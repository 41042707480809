@import './variables.scss';

@mixin card-wrapper {
  position: relative;
  display: flex;
  padding: calc($grid_gap / 2);

  @media screen and (max-width: 400px) {
    padding: calc($grid_gap_mobile / 2);
  }

  width: 25%;

  @media screen and (max-width: $breakpoint-desktop) {
    width: 50%;

    :global(.oneColumn) & {
      width: 100%;
    }
  }
}

@mixin card-indent {
  margin: calc($grid_gap / 2);

  @media screen and (max-width: $breakpoint-small-mobile) {
    margin: calc($grid_gap_mobile / 2);
  }
}

@mixin base-card {
  display: flex;
  padding: 12px;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
  width: 100%;

  background: var(--color-L100);
}

@mixin max-content-width {
  max-width: 1280px;

  :global(.slimContent) & {
    max-width: 940px;
  }
}

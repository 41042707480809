.wrapper {
  position: relative;
  align-items: center;
  max-width: 100%;
  padding-right: 26px;
  line-height: 18px;
  transition: color 0.2s;
  color: var(--color-D60);
  user-select: none;

@media screen and (min-width: 595px) {
  display: none !important;
  }
}

.text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}

.desktop {
  display: none;
}

.mobile {
  display: inline-flex;
}

@import "src/styles/variables";

.page_location {
  > h1, span {
    font-weight: bold;
    font-family: var(--font-aeroport);
    min-height: 30px;
    font-size: 24px;
    line-height: 1;
    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 40px;
      min-height: initial;
    }
  }
}

.region {
  color: var(--color-B100);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #069ed4;
  }

  &:active {
    color: var(--color-B200);
  }
}
